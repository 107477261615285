var site = site || {};
site.Navigation = (function () {
    
    var stickyNav = function(mediaQueries) {

        // if (mediaQueries.xl.matches) {
            
            var utilityNav =  document.querySelector('.utility-nav');
            if (utilityNav) {
                var utilityNavHeight = document.querySelector('.utility-nav').offsetHeight + 1
                
                var stickyHeader = document.querySelector('header')
                stickyHeader.style.top = "-" + utilityNavHeight + 'px';
        
                document.addEventListener('scroll', function() {
                    window.requestAnimationFrame(function() {
                        if (window.scrollY >= utilityNavHeight) {
                            document.querySelector('.js-main-nav').classList.add('stuck')
                        } else {
                            document.querySelector('.js-main-nav').classList.remove('stuck')
                        }
                    })
                });
            }

        // }
    }
    
    return {
        // functionName : functionName
        stickyNav : stickyNav,
    };
    
}());

