$(function() {
    // Bootstrap matching Media Queries
    //To use:
    //      Pass mediaQueries into module function: extranet.Utilities.function(mediaQueries)
    //      In module function:
    //          if(mediaQueries.sm.matches){
    //              code here
    //          }
    var mediaQueries = {
        sm: window.matchMedia('(min-width: 576px)'),
        md: window.matchMedia('(min-width: 768px)'),
        lg: window.matchMedia('(min-width: 992px)'),
        xl: window.matchMedia('(min-width: 1200px)')
    }
    //------------------------------------------------------------------------//
    //  Initialize Lazyloading with support for native lazyload
    //
    //  Defaults to use lazyload plugin, but will fall back to native support 
    //  for browsers with support for the loading="lazy" attribute
    //  (https://caniuse.com/#feat=loading-lazy-attr)
    //------------------------------------------------------------------------//
    var lazyLoadInstance = new LazyLoad({
        elements_selector: ".lazy",
        use_native: true 
    });
    //------------------------------------------------------------------------//
    //  PUBLIC MODULE FUNCTIONS
    //------------------------------------------------------------------------//
    //  Usage Examples: 
        //  site.Utilities.functionName()
    //      site.Utilities.functionName(mediaQueries)
    //------------------------------------------------------------------------//
    site.Navigation.stickyNav(mediaQueries);
    site.Utilities.acceptCookieConsent($);
    site.Utilities.videoModals();
    
    // Check DOM for images loaded via dynamically and update Lazyload instance
    if (lazyLoadInstance) {
        lazyLoadInstance.update();
    }
    
    // Recent News Carousel
    const recentNewsSwiper = new Swiper('.js-recent-news-swiper', {
        // Optional parameters
        autoheight: true,
        direction: 'horizontal',
        grabCursor: true,
        navigation: {
            nextEl: '.carousel-next',
            prevEl: '.carousel-prev',
        },
        slidesPerView: 'auto',
        slidesOffsetAfter: 15,
        slidesOffsetBefore: 15,
        spaceBetween: 20,
        breakpoints: {
            576: {
                slidesOffsetAfter: 0,
                slidesOffsetBefore: 0,
                slidesPerGroup: 2,
                slidesPerView: 2,
            },
            992: {
                slidesOffsetAfter: 0,
                slidesOffsetBefore: 0,
                slidesPerGroup: 3,
                slidesPerView: 3,
            }
        }
    });

    const featuredNewsSwiper = new Swiper('.js-featured-news-swiper', {
        // Optional parameters
        autoheight: true,
        direction: 'horizontal',
        grabCursor: true,
        navigation: {
            nextEl: '.carousel-next-featured',
            prevEl: '.carousel-prev-featured',
        },
        slidesPerView: '1',
        speed: 500
    });


    featuredNewsSwiper.on('slideChangeTransitionStart', () => {
        const swiper = document.querySelector('.js-featured-news-swiper');
        let activeSlide = swiper.querySelector('.swiper-slide-active');
        const slideNumber = activeSlide.dataset.slideNumber // getAttribute('data-slide-number');
        const allImages = document.querySelectorAll('.home-hero-image');
        
        allImages.forEach((image) => {
            const imageNumber = image.dataset.imageNumber;
            image.classList.remove('active-image');
            if (imageNumber == slideNumber) {
                image.classList.add('active-image')
            }
        })
    })

    const upcomingEventSwiper = new Swiper('.js-upcoming-event-swiper', {
        // Optional parameters
        //autoheight: true,
        direction: 'horizontal',
        grabCursor: true,
        navigation: {
            nextEl: '.carousel-next-event',
            prevEl: '.carousel-prev-event',
        },
        slidesPerView: 'auto',
        slidesOffsetAfter: 15,
        slidesOffsetBefore: 15,
        spaceBetween: 20,
        breakpoints: {
            576: {
                slidesOffsetAfter: 0,
                slidesOffsetBefore: 0,
                slidesPerGroup: 2,
                slidesPerView: 2,
            },
            992: {
                slidesOffsetAfter: 0,
                slidesOffsetBefore: 0,
                slidesPerGroup: 3,
                slidesPerView: 3,
            }
        }
    });

    const testimonialSwiper = new Swiper('.js-testimonials-swiper', {
        // Optional parameters
        autoheight: true,
        direction: 'horizontal',
        grabCursor: true,
        // navigation: {
        //     nextEl: '.carousel-next-featured',
        //     prevEl: '.carousel-prev-featured',
        // },
        pagination: {
            el: '.js-carousel-pagination',
            type: 'bullets',
            clickable: 'true',
        },
        slidesPerView: '1',
    });

    $('.js-navbar-toggler').click(function() {
        $('.js-navbar-toggle-icon').toggleClass('d-none');
    })

    $('.js-subnav-toggle').click(function() {
        var thisNav = $(this);
        thisNav.find('.js-subnav-toggle-icon').toggleClass('d-none');
    })


    $('.js-accordion-toggle').click(function() {
        var allToggles = $('.js-accordion-toggle');
        allToggles.removeClass('active')
        setTimeout(function() {
            allToggles.each(function() {
                var toggle = $(this);
                if (!toggle.hasClass('collapsed')) {
                    toggle.find('.js-accordion-icon.fa-plus').addClass('d-none')
                    toggle.find('.js-accordion-icon.fa-minus').removeClass('d-none')
                } else {
                    toggle.find('.js-accordion-icon.fa-plus').removeClass('d-none')
                    toggle.find('.js-accordion-icon.fa-minus').addClass('d-none')
                }
            })
        }, 50)
    })

    $('.js-transcript-toggle').click(function() {
        var toggleText = $('.js-transcript-toggle span');
        toggleText.toggleClass('d-none');
    })

    setTimeout(function(){
        $('.js-pagination-btn-number, .js-pagination-btn-prev, .js-pagination-btn-next').click(function() {
            $('html, body').animate({
                scrollTop: $(".js-listing-anchor").offset().top - 100
            }, 1000);
        })
    }, 1000)

    
    //------------------------------------------------------------------------//
    // WINDOW RESIZE FUNCTIONS
    //------------------------------------------------------------------------//
    $(window).smartresize(function() {
        //------------------------------------------------------------------------//
        //  Public module functions
        //------------------------------------------------------------------------//
        //  Usage Examples: 
        //      site.Utilities.functionName()
        //      site.Utilities.functionName(mediaQueries)
        //------------------------------------------------------------------------//
        site.Navigation.stickyNav(mediaQueries);
    }, 200);
});